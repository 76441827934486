import type { Answer } from '~/services/profile/profileService'
import type { Subscription } from '~/services/subscription/subscriptionService'
import type { User } from '~/services/user/userService'
import type { Customer } from '~/services/customer/customerService'
import type { Coupon } from '~/services/subscription/coupon/couponService'

export interface ProfileInfo {
  questionnaireId: number,
  answers: Answer[]
}

export interface UserInfo {
  email: string,
  password: string
}

export interface CustomerInfo {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  birthDate: string,
  cpf: string,
  termsOfUseVersion: string,
  privacyPolicyVersion: string,
  acceptsPromotionalEmails: boolean,
}

export interface ShippingAddressInfo {
  street: string,
  complement: string | null,
  number: string,
  district: string,
  city: string,
  state: string,
  postalCode: string
}

export interface PaymentProfileInfo {
  creditCardToken: string | null
}

export interface SubscriptionInfo {
  planId: string,
  couponId: string | null,
  giftCoupon: SubscriptionGiftCoupon | null,
}

export interface SubscriptionGiftCoupon {
  couponId: string,
  couponCode: string,
}

export interface MediaAttributionInfo {
  campaignName: string | null,
  source: string | null,
  medium: string | null,
  content: string | null,
  term: string | null
}

export interface FacebookAttributionInfo {
  transactionId: string,
  eventSourceUrl: string | null,
  fbp: string | null,
  fbc: string | null
}

export interface WaffleCustomerSignUpRequest {
  user: UserInfo,
  customer: CustomerInfo,
  profile: ProfileInfo,
  mediaAttribution: MediaAttributionInfo | null,
  fingerprint: string | null
}

export interface WaffleCustomerSignUpResponse {
  user: User,
  customer: Customer,
  token: string,
}

export interface WaffleSubscriptionSignUpRequest {
  billingPlatformId: number,
  user: UserInfo,
  customer: CustomerInfo,
  profile: ProfileInfo,
  shippingAddress: ShippingAddressInfo,
  paymentProfile: PaymentProfileInfo,
  subscription: SubscriptionInfo,
  promotions: string[],
  mediaAttribution: MediaAttributionInfo | null,
  facebookAttribution: FacebookAttributionInfo | null,
  fingerprint: string | null
}

export interface WaffleSubscriptionSignUpResponse {
  user: User,
  subscription: Subscription | null,
  bankSlipUrl: string | null,
  token: string
}

export interface WaffleSubscribeRequest {
  billingPlatformId: number,
  customerId: number,
  shippingAddressId: number,
  paymentProfile: PaymentProfileInfo,
  subscription: SubscriptionInfo,
  experiments: string[],
  promotions: string[],
  mediaAttribution: MediaAttributionInfo | null,
  facebookAttribution: FacebookAttributionInfo | null,
  fingerprint: string | null
}

export interface WaffleSubscribeResponse {
  subscription: Subscription,
  bankSlipUrl: string | null
}

export interface PancakeMigrateSubscriptionRequest {
  planId: string,
  couponId: string | null,
}

export interface PancakeCancelSubscriptionRequest {
  cancelReason: string,
  cancelReasonComment: string
}

export interface PancakeSubscriptionCancelInfo {
  automaticCancellationAllowed: boolean,
  lastInvoice: number | null,
  monthsPerPeriod: number,
  monthsIntoPeriod: number,
  fee: number,
  refund: number,
  dueAmount: number
}

export interface SubscriptionGiftCouponInfo {
  couponId: string,
  couponCode: string,
  giftDescription: string
}

export interface ValidateSubscriptionCouponRequest {
  customerCPF: string,
  couponCode: string,
  planId: string | null
}

export interface ValidateSubscriptionCouponResponse {
  couponAlreadyReceived: boolean,
  couponExhausted: boolean,
  discountCoupon: Coupon | null,
  giftCoupon: SubscriptionGiftCouponInfo | null
}

export default {
  async signUp(request: WaffleSubscriptionSignUpRequest): Promise<WaffleSubscriptionSignUpResponse> {
    return await apiPost(`pancake/sign-up`, { body: request })
  },
  async signUpCustomer(request: WaffleCustomerSignUpRequest): Promise<WaffleCustomerSignUpResponse> {
    return await apiPost(`pancake/sign-up-customer`, { body: request })
  },
  async subscribe(request: WaffleSubscribeRequest): Promise<WaffleSubscribeResponse> {
    return await apiPost(`pancake/subscription`, { body: request})
  },
  async migrate(subscriptionId: number, request: PancakeMigrateSubscriptionRequest): Promise<Subscription> {
    return await apiPost(`pancake/subscription/${subscriptionId}/migrate`, { body: request })
  },
  async getSubscriptionCancelInfo(subscriptionId: number): Promise<PancakeSubscriptionCancelInfo> {
    return await apiGet(`pancake/subscription/${subscriptionId}/cancellation-fee`)
  },
  async requestSubscriptionCancellation(subscriptionId: number, request: PancakeCancelSubscriptionRequest): Promise<void> {
    return await apiPost(`pancake/subscription/${subscriptionId}/cancellation-request`, { body: request })
  },
  async reactivateSubscription(subscriptionId: number): Promise<Subscription> {
    return await apiPost(`pancake/subscription/${subscriptionId}/reactivate`, { body: {} })
  },
  async validateSubscriptionCoupon(request: ValidateSubscriptionCouponRequest): Promise<ValidateSubscriptionCouponResponse> {
    return await apiPost(`pancake/subscription/coupon/validate`, { body: request })
  }
}
